<layer-map>

  <div class="map-box">
    <button class="open-btn {sidebarOpen ? 'sidebar-open' : ''}" onclick={ openSidebar }>&#9776;</button>

    <div class="sidebar {sidebarOpen ? 'sidebar-open' : ''}">
      <a class="close-btn" onclick={ closeSidebar }>&times;</a>
      <div class="controls">
        <select class="layer-select" onchange={ onLayerChanged }>
          <option each={ l in layerDescriptions } value={ l.id } selected={l.id === defaultLayer}>{ l.name }</option>
        </select>
        <div class="features">
          <span each={ (layer, i) in layers }>
            <input type="checkbox" id="feature-checkbox-{mapId}-{i}" checked={ layer.checked } onclick={ toggleGeoJsonLayer(layer.id) }/>
            <label for="feature-checkbox-{mapId}-{i}">{ layer.id }<span if={ layer.state === "loading" }>⚙️</span></label><br/>
          </span>
        </div>
      </div>
    </div>

    <div class="properties-box" if={ state.obj }>
      <tree-search />
    </div>

    <div class="map"></div>
  </div>

  <script>
    import { HereGeoJsonMap } from "./map"
    import * as riot from "riot";

    export default {
      onMounted() {
        this.mapId = Math.floor(Math.random() * 10000);
        this.map = new HereGeoJsonMap(
          this.root.getElementsByClassName('map')[0],
          Object.assign(this.props.config, {
            hereAppId: "x6sXIaeWkAPvZYeyrAhx",
            hereAppCode: "YopQln8NuXmBTMW1rOKvmg",
          }),
        );
        this.defaultLayer = this.props.config.layer || "here.reduced.day";
        this.layerDescriptions = this.map.layerDescriptions();

        this.map.selectHereLayer(this.defaultLayer);
        this.map.on("feature-selected", properties => this.update({ obj: properties }));

        this.layers = [];
        this.props.resources.map(({ value, config }) => {
          const id = config.id || value.name;
          const hidden = config.hasOwnProperty("hidden") && config.hidden !== "false"

          const layer = { id, checked: !hidden, state: "loading" };
          this.layers.push(layer);

          this.map.addGeoJsonLayer(id, "");

          value.observe((error, geoJson) => {
            if (error) {
              layer.state = "error";
              this.props.handleError(error);
            } else {
              this.map.replaceGeoJsonLayer(id, geoJson);
              if (hidden) {
                this.map.disableGeoJsonLayer(id);
              }
              layer.state = "loaded";
            }
            this.update();
          });
        });
      },
      onUpdated() {
        riot.unmount("tree-search", true)
        if (this.state.obj) {
          riot.mount("tree-search", {
            obj: this.state.obj,
            show: true,
            showDepth: 2,
          });
        }
      },
      onLayerChanged(e) {
        this.map.selectHereLayer(e.target.value)
      },
      toggleGeoJsonLayer(layerId) {
        return (e) => e.target.checked ? this.map.enableGeoJsonLayer(layerId) : this.map.disableGeoJsonLayer(layerId);
      },
      openSidebar(e) {
        this.sidebarOpen = true;
        this.update();
      },
      closeSidebar(e) {
        this.sidebarOpen = false;
        this.update();
      },
    }
  </script>
</layer-map>
