<item-select>
  <div class="select-box">
    <select onchange={ onItemSelected }>
      <option each={ item in props.definition.items } value={ item.id } key={ item.id } selected={ item.id === state.selectedId }>{ item.label || item.id }</option>
    </select>
    <input class="select-input { state.selectClass }" type="text" value={ state.inputId } onkeyup={ onIdInput } onpaste={ onIdInput }/>
    <button class="select-btn" onclick={ onGoClick }>Select</button>
    <button class="select-btn select-modal-btn-{idx}" each={ (modal, idx) in Object.entries(props.definition.modals || {}) } onclick={ showModal(modal[0], modal[1]) }>{ modal[0] }</button>
  </div>

  <div class="app" />

  <modal if={ state.modalQueryString } on-close={ onModalClosed } title={ state.modalTitle }>
    <app query-string={ state.modalQueryString } />
  </modal>

  <script>
    import * as riot from "riot";
    import { serialize } from "./parameters";

    export default {
      onBeforeMount() {
        this.keyboardShortcutsHandler = e => {
          const nextItem = inc => {
            const currentIndex = this.props.definition.items.findIndex(item => item.id === this.state.selectedId);
            const newItem = this.props.definition.items[currentIndex + inc];
            if (newItem) {
              this.setItem(newItem);
            }
            e.stopImmediatePropagation();
          };

          if (e.ctrlKey) {
            switch (e.key) {
              case "j":
                nextItem(1);
                break;
              case "k":
                nextItem(-1);
                break;
              default:
            }
          }
        };
      },
      onMounted() {
        this.appElement = this.root.getElementsByClassName("app")[0];
        const initialSelection = this.props.definition.items.find(item => item.id === this.props.config.default) ||
                                 this.props.definition.items[0]

        if (initialSelection) {
          this.setItem(initialSelection);
        }

        window.addEventListener("keydown", this.keyboardShortcutsHandler, true);
      },
      onUnmounted() {
        document.removeEventListener("keydown", this.keyboardShortcutsHandler, true);
      },
      onIdInput(event) {
        const inputId = event.target.value;
        this.update({
          inputId,
          selectClass: inputId !== this.state.selectedId ? "select-input-changed" : undefined,
        });
        if (event.key === "Enter") {
          this.onGoClick();
        }
      },
      onGoClick() {
        const item = this.props.definition.items.find(item => item.id === this.state.inputId);
        if (item) {
          this.setItem(item);
        } else {
          this.update({ selectClass: "select-input-invalid" });
        }
      },
      onItemSelected(event) {
        this.setItem(this.props.definition.items[event.target.selectedIndex]);
      },
      setItem(item) {
        this.update({
          inputId: item.id,
          selectedId: item.id,
          selectedItem: item,
          selectClass: undefined,
        });

        riot.unmount(this.appElement, true);
        riot.mount(this.appElement, {
          queryString: this.prepareQueryString(this.props.definition.parameters),
        }, "app");
      },
      showModal(name, viewDefinition) {
        return e => {
          this.update({
            modalQueryString: this.prepareQueryString({
              url: viewDefinition,
            }),
            modalTitle: name,
          });
        };
      },
      onModalClosed() {
        this.update({ modalQueryString: undefined });
      },
      prepareQueryString(parameters) {
        return serialize(parameters, str => {
          return Object.entries(this.state.selectedItem)
            .reduce((q, [key, value]) => q.replace(new RegExp(`\\\$\\{${key}\\}`, "g"), value), str);
        });
      },
    }
  </script>
</item-select>
