<app>
  <error-view error={ error } />

  <split-view if={!syntaxError} {...{ viewDefinition }} />

  <script>
    import parse from "../parser";

    export default {
      onBeforeMount() {
        try {
          this.viewDefinition = parse(new URLSearchParams(this.props.queryString));
        } catch (error) {
          this.error = error;
        }
      },
    }
  </script>
</app>
