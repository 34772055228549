<content-frame>

  <script>
    import * as riot from "riot";
    import { mount } from "../views";

    export default {
      onBeforeMount() {
        const handleError = error => {
          const errorView = document.createElement("error-view");
          this.root.innerHTML = "";
          this.root.append(errorView);
          riot.mount(errorView, { error }, "error-view");
        };

        if (this.props.view.error) {
          handleError(this.props.view.error);
        } else {
          new Promise((resolve, reject) => resolve(mount(this.root, this.props.view, handleError)))
            .catch(handleError);
        }
      },
    }
  </script>
</content-frame>
