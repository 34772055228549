<error-view>
  <div if={ error }>
    <h1>{ error.title || error.name }</h1>
    <p>{ error.message || error }</p>

    <div if={ error.name === "InvalidLayout" }>
      <p><pre><code><span style="color: #999">{error.details.prefixSection}</span><span style="border: 1px solid red; background-color: pink">{error.details.errorSection}</span><span style="color: #999">{error.details.suffixSection}</span></code></pre></p>
      <p>You may want to refer to the Layout section of the <a href="/#layout">documentation</a>.</p>
    </div>

    <div if={ error.name === "InvalidView" }>
      <p><pre><code><span style="color: #999">{error.details.prefixSection}</span><span style="border: 1px solid red; background-color: pink">{error.details.errorSection}</span><span style="color: #999">{error.details.suffixSection}</span></code></pre></p>
    </div>

    <div if={ error.name === "UnknownViewType" }>
      <div if={error.details.knownHandlers}>
        <p>Known view types (see the <a href="/">documentation</a> for details):</p>
        <ul>
          <li each={h in error.details.knownHandlers.sort()}>{h}</li>
        </ul>
      </div>
    </div>

    <div if={ error.name === "UndefinedView" }>
      <div if={error.details.availableParameters}>
        <p>Known views from URL parameters:</p>
        <ul>
          <li each={p in error.details.availableParameters.sort()}>'{p}'</li>
        </ul>
      </div>
    </div>
  </div>

  <script>
    export default {
      onBeforeMount() {
        this.updateError();
      },
      onBeforeUpdate() {
        this.updateError();
      },
      updateError() {
        const error = this.props.error;
        if (error) {
          console.error(error);
          this.error = error instanceof Error ? error : new Error(error);
        }
      },
    }
  </script>
</error-view>
