<multi-tab>
  <div class="multi-tab">
    <div class="tabs">
      <span each={ (tab, index) in tabs } onclick={ selectTab(index) } class="{index === state.selectedIndex ? "tab-selected" : "tab-hidden"}">
        {tab.name}
      </span>
    </div>

    <div class="contents">
      <div each={ (tab, index) in tabs } class="tab-content-{index} {index === state.selectedIndex ? "content-selected" : "content-hidden"}">
        <app query-string={tab.parameters} />
      </div>
    </div>
  </div>

  <style>
    .tabs {
      padding: 2px 10px 2px 10px;
      border-bottom: 1px solid #b5b5b5;
    }
    .tabs span {
      cursor: pointer;
      padding: 2px 10px 2px 10px;
    }
    .tabs .tab-selected {
      background-color: #b5b5b5;
    }
    .contents .content-hidden {
      display: none;
    }
  </style>

  <script>
    import { serialize } from "./parameters";

    export default {
      onBeforeMount() {
        this.tabs = Object.entries(this.props.tabs).map(([name, view]) => {
          return {
            name,
            parameters: serialize({
              "url": view,
            }),
          };
        });
        const defaultIndex = this.tabs.findIndex(({ name }) => name === this.props.config.default);
        this.state.selectedIndex = defaultIndex === -1 ? 0 : defaultIndex;
      },
      selectTab(index) {
        return () => {
          this.update({
            selectedIndex: index,
          });
          window.dispatchEvent(new Event("resize"));
        };
      },
    }
  </script>
</multi-tab>
